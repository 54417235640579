import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import view from "./cookie.module.scss"

export default function CookiePolicy(props) {
  const { dealerInfo, CookieLinkText } = props
  const disclaimer = props.disclaimer

  const localStorage = typeof window !== 'undefined' && window.localStorage
  const privModal = getStorageItem('CookiePolicyShown');

  const [modalVisible, toggleModal] = useState(false)

  function getStorageItem(item) {
    return (typeof localStorage.getItem !== 'undefined' ? localStorage.getItem(item) : false)
  }

  function setStorageItem(item, value) {
    typeof localStorage.setItem !== 'undefined'
      ? localStorage.setItem(item, value)
      : console.log('no localStorage')
  }

  const closeModal = () => {
    toggleModal(false)
    setStorageItem('CookiePolicyShown', true);
  }

  const openModel = () => {
    toggleModal(true)
  }

  useEffect(() => {
    if (privModal === null) {
      toggleModal(true);
    }
  });
  return (
    <>{
      modalVisible ? (
        <div className={`${view["cookie-modal"]}  cookie-disclaimer-container`}>
          <div className={`${view["cookieFlex"]}`}>
            {
              disclaimer ?
                (
                  <>
                    <div dangerouslySetInnerHTML={{ __html: disclaimer }} />
                    <span className={`${view["close-modal-link"]} close-modal`} onClick={closeModal}>
                      {`  Close  `} 
                    </span>
                  </>
                ) :
                (
                  <p>
                    By continuing to use our site, you agree to our
                    <Link target="_blank" to={dealerInfo.termsOfService} className={`${view["modal-links"]}`}> Terms of Service </Link> and
                    <Link target="_blank" to={dealerInfo.privacy} className={`${view["modal-links"]}`}> Privacy Policy</Link>.
                    You can learn more about how we use cookies by reviewing our
                    <Link target="_blank" to={dealerInfo.privacy}
                      className={`${view["modal-links"]}`}> Privacy  Policy</Link>.
                    <span className={`${view["close-modal-link"]} close-modal`} onClick={closeModal}> {`  Close  `} </span>
                  </p>
                )
            }
          </div>
        </div>
      ) : (<></>)
    }
      <div className={` ${view["cookie-policy"]} subtitle`} >
        <span onClick={openModel}> {CookieLinkText}</span>
      </div>
    </>
  )
}

CookiePolicy.defaultProps = {
  visible: true,
  closeModal: () => { }
}