import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from '@reach/router';
import Loader from "react-loader-spinner";

import useDataLayer from '../../../hooks/useDataLayer';
import mfc from '../modules/modalFormClient';

import { captureEvent } from 'event-service/modules/capture'

import "../../contact/ContactForm.scss"
import grid from "../../../scss/flexboxgrid.module.scss"
import view from "../content/form.module.scss"

function EmailForm(props) {
  const { siteID, leadURL, disclaimerContact, gtmId } = props

  const pushToDataLayer = useDataLayer()

  const [fields, setFields] = useState({
    firstName: null, lastName: null, email: null,
    siteId:siteID, pageSource: props.pageSource, pageURL: props.pageURL, notes: '', button: '', formType: ''
  })

  const location = useLocation()
  useEffect(() => {
    if(props.autofillable){
      const userForm = {}
      const params = new URLSearchParams(location.search)
      for (const [key, value] of params) {
        fields.hasOwnProperty(key) && (userForm[key] = value);
      }
      setFields({ ...fields, ...userForm})
    }
  }, [])

  const [error, setError] = useState([])

  const [statusMsg, setStatusMsg] = useState("")
  const [statusNote, setStatusNote] = useState("")
  const [loading, isLoading] = useState(false)
  const [success, setSuccess] = useState(false )
  const [engagement, setEngagement] = useState(false)

  useEffect(() => {
    if (engagement) {
      captureEvent({
        event: 'asc_form_engagement',
        comm_type: 'form',
        comm_status: 'start',
        element_text: engagement?.innerText,
        department: 'sales',
        form_name: 'Email Newsletter',
        form_type: 'sales'
      }, {
        handlers: ['ASC']
      }).then(res => console.log(res)).catch(err => console.log(err))
    }
  }, [engagement])

  const handleTextInput = (name, value) => { 
    const isErrored = error.indexOf(name)
    if(isErrored > -1){
      const _error = [...error]
      _error.splice(isErrored, 1)
      setError(_error)
    }

    setStatusMsg("")
    setFields({ ...fields, ...{ [name]: value } })
  }

  const submitForm = async (e) => {
    e.preventDefault()

    const missing = mfc.validateForm(fields, ['firstName', 'lastName', 'email'])
    if (missing.length > 0) {
      setError([...missing])
      return setStatusMsg("One or more fields are missing!")
    }

    isLoading(true)

    const body = fields
    const result = await mfc.sendForm(leadURL, body)
    if (result.hasOwnProperty("error")) {
      setStatusMsg("Uh oh! Something went wrong, please try again!")
    } else {
      captureEvent({
        event: 'asc_form_submission',
        comm_type: 'form',
        comm_outcome: 'crm_update',
        submission_id: `${result.result.data.LeadID.id}`,
        element_text: 'submit',
        department: 'sales',
        form_name: 'Email Newsletter',
        form_type: 'sales'
      }, {
        handlers: ['ASC']
      }).then(res => console.log(res)).catch(err => console.log(err))
      setSuccess(true)
      pushToDataLayer("form-submitted", result)
      pushToDataLayer("email", result)
      setStatusMsg("Success! You have been subscribed!")
      setStatusNote(fields.notes)
    }
    isLoading(false)

  }

  return (
    (
      <div className={`contact-formCont ${view["contact-form"]}`}>
        <div className={`contact-inputCont ${grid["col-md-6"]} ${grid["col-sm-12"]}`} onClick={() => setEngagement({innerText: "First Name"})}>
          <label htmlFor="firstName">First Name *</label>
          <input className="contact-input" id="firstName" name="firstName"
            value={fields.firstName} placeholder=""
            style={{ border: error.indexOf("firstName") > -1 && "1px solid red" }}
            onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
        </div>

        <div className={`contact-inputCont ${grid["col-md-6"]} ${grid["col-sm-12"]}`} onClick={() => setEngagement({innerText: "Last Name"})}>
          <label htmlFor="lastName">Last Name *</label>
          <input className="contact-input" id="lastName" name="lastName"
            value={fields.lastName} placeholder=""
            style={{ border: error.indexOf("lastName") > -1 && "1px solid red" }}
            onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
        </div>

        <div className={`contact-inputCont ${grid["col-md-6"]} ${grid["col-sm-12"]}`} onClick={() => setEngagement({innerText: "Email"})}>
          <label htmlFor="email">Email *</label>
          <input className="contact-input" type="email" id="email" name="email"
            value={fields.email} placeholder=""
            style={{ border: error.indexOf("email") > -1 && "1px solid red" }}
            onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
        </div>

        <div className="contact-inputCont contact-border">
          <div className={`contact-response ${statusMsg.length && "contact-failure"}`}>
            {statusMsg}
            <p className='contact-status-note'>{statusNote}</p>
          </div>

          <div className="contact-disclaimer" dangerouslySetInnerHTML={{ __html: disclaimerContact }}></div>
          {
            !success && !loading ?
            <button className="contact-button" onClick={submitForm}>
              SIGN UP
            </button>  :
            <div className={success ? "circle-loader-contact load-complete-contact" : "circle-loader-contact"}>
              <div className={success ? "checkmark-contact draw" : ""}></div>
            </div>
          }


        </div>


      </div>
    )
  )
}

EmailForm.defaultProps = {
  leadURL: "",
  disclaimerContact: "<p></p>"
}

export default EmailForm
